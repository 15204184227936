import React, { useState } from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
// import FloatingLabel from "react-bootstrap/FloatingLabel";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "./LoginInputField.css";
import { Card } from "react-bootstrap";

const LoginInputField = ({
    alert,
    autoComplete,
    buttonLabel,
    changeHandler,
    className,
    description,
    inputMode,
    instructions,
    label,
    maxLength,
    name,
    pattern,
    submitHandler,
    type,
    value,
}) => {
    const [termsCheck, setTermsCheck] = useState(false);

    const handleTermsCheck = () => {
        setTermsCheck(!termsCheck);
    };
    // useEffect(() => {
    //     const queryParameters = new URLSearchParams(window.location.search);
    //     const type = queryParameters.get("index");
    // }, []);

    const loginHandler = (e) => {
        e.preventDefault();
        submitHandler();
    };

    return (
        <Container className="login-container fade-in">
            <Row className="justify-content-center">
                <Col style={{ display: "flex", justifyContent: "center" }}>
                    <Form
                        onSubmit={(e) => loginHandler(e)}
                        className="login-form"
                    >
                        <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            className="login-instructions"
                        >
                            {instructions}
                        </ReactMarkdown>
                        <Card
                            style={{
                                maxWidth: "fit-content",
                                height: "auto",
                                borderRadius: "15px !important",
                            }}
                            className="login-box"
                        >
                            <Card.Title
                                style={{ width: "428px" }}
                                className="typography"
                            >
                                <span>Applying for Residency ? Great</span>
                            </Card.Title>
                            <Col className="login-form-inside">
                                <div
                                    className="inputBtnContainer"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        gap: "34px",
                                    }}
                                >
                                    <div className="login-form-above-part">
                                        <Card.Text className="label">
                                            <span>Enter Code</span>
                                        </Card.Text>
                                        <Form.Control
                                            autoComplete={autoComplete}
                                            className={`login-input-field ${className}`}
                                            inputMode={inputMode}
                                            maxLength={maxLength}
                                            name={name}
                                            pattern={pattern}
                                            size="lg"
                                            type={type}
                                            onChange={changeHandler}
                                            value={value || ""}
                                        />
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "flex-start",
                                                gap: "6px",
                                            }}
                                        >
                                            <Form.Check
                                                type="checkbox"
                                                abel={`disabled ${type}`}
                                                id={`disabled-default-${type}`}
                                                className="check-div"
                                                name="terms"
                                                onChange={handleTermsCheck}
                                            />
                                            <label
                                                htmlFor={`disabled-default-${type}`}
                                                className="terms"
                                            >
                                                I agree to all the terms and
                                                conditions
                                            </label>
                                        </div>
                                    </div>

                                    <Button
                                        style={{
                                            width: "428px",
                                            height: "48px",
                                        }}
                                        variant="primary"
                                        size="lg"
                                        className="login-submit-button"
                                        type="submit"
                                        disabled={!termsCheck || !value}
                                    >
                                        Submit code and start test
                                    </Button>
                                </div>
                            </Col>
                        </Card>
                        {/* 
                        <FloatingLabel controlId={name} label={label}>
                        <Form.Control
                        autoComplete={autoComplete}
                        className={`login-input-field ${className}`}
                        inputMode={inputMode}
                        maxLength={maxLength}
                        name={name}
                        pattern={pattern}
                                size="lg"
                                type={type}
                                onChange={changeHandler}
                                placeholder={label}
                                value={value || ""}
                            />
                        </FloatingLabel>
                        <Button variant="primary" size="lg" className="login-submit-button" type="submit">
                            {buttonLabel}
                        </Button>
                        {alert}
                    */}
                    </Form>
                </Col>
            </Row>

            <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                className="login-description text-center"
            >
                {description}
            </ReactMarkdown>
        </Container>
    );
};

LoginInputField.propTypes = {
    alert: PropTypes.element,
    autoComplete: PropTypes.string,
    buttonLabel: PropTypes.string.isRequired,
    changeHandler: PropTypes.func.isRequired,
    className: PropTypes.string,
    description: PropTypes.string,
    inputMode: PropTypes.string.isRequired,
    instructions: PropTypes.string,
    label: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    name: PropTypes.string.isRequired,
    pattern: PropTypes.string,
    submitHandler: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default LoginInputField;
