import { useSetRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { useErrorBoundary } from "react-error-boundary";
import {
    answersAtom,
    assessmentIdAtom,
    demographicsAtom,
    loginCodeAtom,
    loginFormAtom,
    loginStatusAtom,
    progressionsAtom,
    respondentAtom,
} from "_atoms";
import {
    appendToPreviousStatusCodes,
    setLoginStatusAfterFailedLogin,
    storeSessionInfo,
} from "_helpers/login-helpers";
import { loginPostQuery } from "_queries/login-queries";

/**
 * Actions to log in or log out.
 */
const useLoginActions = (e) => {
    e?.preventDefault();
    /** @type LoginForm */
    const loginForm = useRecoilValue(loginFormAtom);
    /** @type {Dispatch<SetStateAction<LoginCode>>} */
    const setLoginCode = useSetRecoilState(loginCodeAtom);
    /** @type {Dispatch<SetStateAction<LoginStatus>>} */
    const setLoginStatus = useSetRecoilState(loginStatusAtom);
    /** @type function */
    const resetAnswers = useResetRecoilState(answersAtom);
    /** @type function */
    const resetAssessmentId = useResetRecoilState(assessmentIdAtom);
    /** @type function */
    const resetDemographics = useResetRecoilState(demographicsAtom);
    /** @type function */
    const resetLoginForm = useResetRecoilState(loginFormAtom);
    /** @type function */
    const resetLoginStatus = useResetRecoilState(loginStatusAtom);
    /** @type function */
    const resetProgressions = useResetRecoilState(progressionsAtom);
    /** @type function */
    const resetRespondent = useResetRecoilState(respondentAtom);

    /** Hook provided by react-error-boundary for sharing errors with the nearest boundary */
    const { showBoundary } = useErrorBoundary();

    /**
     * Handle an error from an unsuccessful API call.
     * @param {AxiosError|AxiosResponse} axiosError - The error object returned by axios
     * @return {void}
     */
    function handleFailure(axiosError) {
        try {
            // Update the login status to reflect the failures
            const data = axiosError?.response?.data || axiosError?.data;
            setLoginStatusAfterFailedLogin(data, setLoginStatus);

            // If a login code was returned, set it
            if (data?.loginCode?.id) {
                setLoginCode(data.loginCode);
            }

            // Remove any session variable
            sessionStorage.removeItem("session");

            // Reset the app state back to its default status
            resetAnswers();
            resetAssessmentId();
            resetDemographics();
            resetProgressions();
            resetRespondent();
        } catch (error) {
            console.log({ error: error, axiosError: axiosError });
            const errorMessage =
                axiosError?.response?.data?.message ||
                axiosError?.data?.message ||
                axiosError;
            logout(); // Reset the app state back to default so the user can try again
            showBoundary(errorMessage); //Display the nearest error boundary
        }
    }

    /**
     * After a successful API call, update the Recoil state values for the app
     * @param {AxiosResponse} axiosResponse - The http response object from axios
     * @return {void}
     */
    function handleSuccess(axiosResponse) {
        try {
            const data = axiosResponse?.data;
            storeSessionInfo(
                data?.id,
                data?.token?.access,
                data?.token?.refresh
            );
            setLoginCode(data.loginCode);
            setLoginStatus(
                /** @type LoginStatus */
                {
                    currentStatusCode: data?.status,
                    errorMessage: "",
                    previousStatusCodes: [],
                }
            );
        } catch (error) {
            handleFailure(axiosResponse);
        }
    }

    /**
     * Post a new login to the API.
     * @param {Event} [e] - A form submission event
     * @return {Promise} - Returns the axios promise
     */
    function login(e) {
        if (e) e.preventDefault();
        setLoginStatus(
            (prev) =>
                /** @type LoginStatus*/
                ({
                    ...prev,
                    currentStatusCode: "PL",
                    previousStatusCodes: appendToPreviousStatusCodes(
                        prev.previousStatusCodes,
                        prev.currentStatusCode
                    ),
                })
        );
        const query = loginPostQuery(loginForm);
        query.then(handleSuccess).catch(handleFailure);
        return query;
    }

    /**
     * Log out, clearing App state.
     * @param {Event} [e] - A click event on a logout link
     * @return {void}
     */
    function logout(e) {
        if (e) e.preventDefault();

        sessionStorage.removeItem("session");
        resetAnswers();
        resetAssessmentId();
        resetDemographics();
        resetLoginForm();
        resetLoginStatus();
        resetProgressions();
        resetRespondent();
    }

    return { login, logout };
};

export default useLoginActions;
